import React, { useLayoutEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import Modal from "react-modal"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

// import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import { AcfCoverContent } from "../components/gutenberg/acf-cover-content"
import { AcfTextWImgColWoContainer } from "../components/gutenberg/acf-text-w-image-wo-container"
import { AcfTextWImgColCustom } from "../components/gutenberg/acf-text-w-img-col"
import { Section, Container } from "../components/anti/grid/grid"
import { CoreGroup } from "../components/gutenberg/core-group"
import { CoreParagraph } from "../components/gutenberg/core-paragraph"
import { AcfHeadlineWShortcode } from "../components/gutenberg/acf-headline"
import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { Card } from "../components/anti/card/card"
import { Link } from "../components/anti/utils/utils"
import { isConstructorDeclaration } from "typescript"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const compareGroup = (a, b) => {
  const groupA = a.group
  const groupB = b.group

  let comparison
  if (groupA > groupB) {
    comparison = 1
  } else if (groupA < groupB) {
    comparison = -1
  }

  return comparison
}
const compareTitle = (a, b) => {
  const titleA = a.title
  const titleB = b.title

  let comparison
  if (titleA > titleB) {
    comparison = 1
  } else if (titleA < titleB) {
    comparison = -1
  }

  return comparison
}

const FacilitiesDetailsPage = ({ pageContext, location }) => {
  const { lang, setLang } = useContext(LangContext)
  const [modal, setModal] = useState({ data: null, show: false })
  const { seo } =
    pageContext.langKey === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data
  const wp =
    lang === "EN"
      ? pageContext.data?.blocks
      : pageContext.data?.translation?.blocks || pageContext.data?.blocks

  const wpID =
    pageContext.data?.translation && pageContext.data.translation?.blocks
  const currentSlug = pageContext.data.slug || pageContext.slug
  const facilities =
    pageContext.facilities &&
    pageContext.facilities.wordPress.facility_categories && 
    pageContext.facilities.wordPress.facility_categories.nodes
  const category =
    pageContext.categories &&
    pageContext.categories.find(x => x.slug === currentSlug)
  let facilitiesData
  facilities &&
    facilities.map(item => {
      if (item.slug === "education") {
        item.children.nodes.find(x => x.slug === currentSlug) &&
          (facilitiesData = item.children.nodes.find(
            x => x.slug === currentSlug
          ))
      } else {
        facilities.map(item => {
          item.slug === currentSlug &&
            (facilitiesData = facilities.find(x => x.slug === currentSlug))
        })
      }
    })

  let data =
    facilitiesData &&
    facilitiesData.facilities.nodes &&
    facilitiesData.facilities.nodes.reduce((result, currentValue) => {
      let group = currentValue.title[0]
      if (!result[group]) {
        result[group] = { group, children: [currentValue] }
      } else {
        result[group].children.push(currentValue)
      }
      return result
    }, {})

  let result = data && Object.values(data)

  const colTextWImage = modal.data && modal.data.blocks[0].colTextWImage

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  const Shortcode = ({ data, lang }) => {
    if (data.attributes.text === "facility-list") {
      return (
        <Section className="facility">
          <Container>
            {result &&
              result.sort(compareGroup).map((item, index) => {
                return (
                  <div className="mb-md-5 mb-3" key={`${item.group}${index}`}>
                    <h2 className="facility-title">{item.group}</h2>
                    <div className="row">
                      {item.children.sort(compareTitle).map((child, i) => {
                        return (
                          <div
                            className="col-md-6 mb-3"
                            key={`${child.title}${i}`}
                            onClick={() => {
                              openModal(child, lang)
                            }}
                          >
                            <div className="facility-item pr-3 pr-md-5">
                              {lang == "EN" && (
                                <ReactMarkdown
                                  source={child.title}
                                  escapeHtml={false}
                                  className="btn-hover"
                                />
                              )}
                              {lang == "ID" &&
                                child.translation != undefined && (
                                  <ReactMarkdown
                                    source={child.translation.title}
                                    escapeHtml={false}
                                    className="btn-hover"
                                  />
                                )}
                              {lang == "ID" && child.translation == null && (
                                <ReactMarkdown
                                  source={child.title}
                                  escapeHtml={false}
                                  className="btn-hover"
                                />
                              )}
                              {/* <i className="fas fa-long-arrow-alt-right"></i> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </Container>
        </Section>
      )
    } else {
      return null
    }
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const openModal = data => {
    setModal({ data: data, show: true })
  }

  const closeModal = () => {
    setModal({ show: false })
  }

  const SEODefaultImg =
    lang === "EN"
      ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
      : pageContext?.data?.translation?.blocks[0]?.coverMain?.img
          ?.mediaItemUrl ||
        pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  return (
    <>
      <Layout>
        <SEO
          title={seo.title}
          desc={seo.metaDesc}
          fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
          fbTitle={seo.opengraphTitle}
          fbDesc={seo.opengraphDescription}
          url={location.href}
          twitterTitle={seo.twitterTitle}
          twitterDesc={seo.twitterDescription}
          twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
        />
        {lang == "EN" &&
          wp.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={pageContext.data && pageContext.data.title}
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return (
                  <AcfCoverContent data={block} key={`${block.name}${i}`} />
                )
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return (
                  <Shortcode
                    lang={lang}
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              default:
                break
            }
          })}
        {lang == "ID" &&
          wpID != undefined &&
          wpID.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={
                      pageContext.data.translation &&
                      pageContext.data.translation.title
                    }
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return (
                  <AcfCoverContent data={block} key={`${block.name}${i}`} />
                )
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return (
                  <Shortcode
                    lang={lang}
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              default:
                break
            }
          })}
        {lang == "ID" &&
          wpID == undefined &&
          wp.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={pageContext.data && pageContext.data.title}
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return (
                  <AcfCoverContent data={block} key={`${block.name}${i}`} />
                )
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return (
                  <Shortcode
                    lang={lang}
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              default:
                break
            }
          })}
        <Modal
          // overlayClassName="d-md-fixed d-none"
          isOpen={modal.show}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="failcity details"
          className="d-md-block d-none"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close">
              <i className="fal fa-times" onClick={closeModal}></i>
            </div>
            <Container>
              {modal.data &&
                (modal?.data?.blocks[0]?.name === "acf/col-text-w-image" ||
                  modal?.data?.blocks[0]?.name ===
                    "acf/col-text-w-image-facilities") && (
                  <AcfTextWImgColCustom data={modal?.data} lang={lang} />
                )}
            </Container>
          </div>
        </Modal>
      </Layout>

      {/* mobile drawer */}
      <div
        className={`d-md-none d-block facilities-drawer-mobile ${
          modal.show ? "show" : ""
        }`}
      >
        <div className="drawer-body">
          <div className="drawer-close">
            <div className="stripe" />
          </div>
          {colTextWImage &&
          colTextWImage.variant &&
          colTextWImage.variant === "paragraph_only" ? (
            <Section>
              <Container className="mw-sm">
                <h2>{colTextWImage.title}</h2>
                <p>{colTextWImage.text}</p>
              </Container>
            </Section>
          ) : (
            colTextWImage && (
              <Section>
                <Container>
                  <div className="row row-5">
                    <div className="col-md-6 mb-sm-down-4">
                      <Card
                        img={colTextWImage.img && colTextWImage.img.sourceUrl}
                        imgHeight="h-ratio-2-1"
                      />
                    </div>
                    <div className="col-md-6">
                      <h2 className="mb-3">{colTextWImage.title}</h2>
                      <p className="lead">{colTextWImage.text}</p>
                      <div className="row">
                        {colTextWImage &&
                          colTextWImage.list &&
                          colTextWImage.list.length > 0 &&
                          colTextWImage.list.map((item, i) => {
                            return (
                              <React.Fragment>
                                <div className="col-1 d-flex align-items-center">
                                  <img
                                    className="facilities-list-icon"
                                    src={item.icon && item.icon.sourceUrl}
                                    alt="list item"
                                  />
                                </div>
                                <div className="col-11">
                                  {item.text && !item.links && (
                                    <p className="mb-2">{item.text}</p>
                                  )}
                                  {item.links && (
                                    <Link
                                      className="py-0"
                                      target={item.links.target}
                                      to={item.links.url}
                                    >
                                      {item.text}
                                    </Link>
                                  )}
                                </div>
                              </React.Fragment>
                            )
                          })}
                      </div>

                      {colTextWImage.button &&
                        colTextWImage.button.link &&
                        colTextWImage.button.link.target === "_blank" && (
                          <a
                            className="btn btn-secondary btn-block direction-link mt-4"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              colTextWImage.button &&
                              colTextWImage.button.link.url
                            }
                          >
                            {colTextWImage.button && colTextWImage.button.text}
                          </a>
                        )}
                      {colTextWImage.button &&
                        colTextWImage.button.link &&
                        colTextWImage.button.link.target === "" && (
                          <button
                            className="btn btn-secondary btn-block direction-link mt-4"
                            onClick={() =>
                              navigate(`/${colTextWImage.button.text}`)
                            }
                          >
                            {colTextWImage.button && colTextWImage.button.text}
                          </button>
                        )}
                    </div>
                  </div>
                </Container>
              </Section>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default FacilitiesDetailsPage
