import React from "react"
import { graphql, navigate } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Button } from "../../anti/buttons/buttons"
import { Section, Container } from "../../anti/grid/grid"
import { Card } from "../../anti/card/card"
import { Link } from "../../anti/utils/utils"

export const Fragment_AcfTextWImgCol = graphql`
  fragment AcfColTextWImageBlock on WordPress_AcfColTextWImageBlock {
    name
    attributes {
      className
    }
    colTextWImage {
      order
      bgColor
      title
      text
      img {
        sourceUrl(size: LARGE)
      }
      buttons {
        text
        link {
          target
          url
        }
      }
    }
  }
`

export const AcfTextWImgCol = ({ data }) => {
  const colTextWImage = data.colTextWImage
  return (
    <Section
      className={`text-w-image-column ${colTextWImage.order} ${colTextWImage.bgColor} ${data.attributes.className}`}
    >
      <Container>
        <div className="row row-5">
          <div className="col-md-6 mb-3 d-flex flex-column justify-content-center align-items-start">
            <h2 className="h2">
              <ReactMarkdown source={colTextWImage.title} escapeHtml={false} />
            </h2>
            <p className="lead mb-0">
              <ReactMarkdown source={colTextWImage.text} escapeHtml={false} />
            </p>
            {colTextWImage.buttons !== null &&
              colTextWImage.buttons.map((button, i) => {
                return (
                  <Button
                    variant="link"
                    target={button?.link?.target}
                    link={button?.link?.url}
                    key={`${i}`}
                  >
                    {button.text}
                  </Button>
                )
              })}
          </div>
          <div className="col-md-6 mb-sm-down-4">
            <Card img={colTextWImage?.img?.sourceUrl} imgHeight="h-ratio-1-1" />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export const AcfTextWImgColCustom = ({ data, lang }) => {
  const colTextWImage =
    lang == "EN"
      ? data?.blocks[0]?.colTextWImage
      : lang == "ID" && data?.translation !== undefined
      ? data?.translation?.blocks[0]?.colTextWImage
      : lang == "ID" && data.translation === undefined
      ? data?.blocks[0]?.colTextWImage
      : data?.blocks[0]?.colTextWImage
  // const colTextWImage = data && data.blocks[0].colTextWImage
  return (
    <Section>
      {colTextWImage?.variant && colTextWImage?.variant === "paragraph_only" ? (
        <Container className="mw-sm">
          <h2>{colTextWImage.title}</h2>
          <p>{colTextWImage.text}</p>
        </Container>
      ) : (
        <Container>
          <div className="row row-5">
            <div className="col-md-6 mb-sm-down-4">
              <Card
                img={colTextWImage?.img && colTextWImage?.img.sourceUrl}
                imgHeight="h-ratio-2-1"
              />
            </div>
            <div className="col-md-6">
              <h2 className="mb-3">{colTextWImage?.title}</h2>
              <p className="lead">{colTextWImage?.text}</p>
              <div className="row">
                {colTextWImage?.list &&
                  colTextWImage?.list.length > 0 &&
                  colTextWImage?.list.map((item, i) => {
                    return (
                      <React.Fragment key={`${item.text}${i}`}>
                        <div className="col-1 d-flex align-items-center">
                          <img
                            className="facilities-list-icon"
                            src={item.icon && item.icon.sourceUrl}
                            alt="list item"
                          />
                        </div>
                        <div className="col-11">
                          {item.text && !item.links && (
                            <p className="mb-2">{item.text}</p>
                          )}
                          {item.links && (
                            <>
                              <Link
                                className="py-0"
                                target={item.links.target}
                                to={item.links.url}
                              >
                                {item.text}
                              </Link>
                            </>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
              {colTextWImage?.button &&
                colTextWImage?.button?.link &&
                colTextWImage?.button?.link?.target === "_blank" && (
                  <a
                    className="btn btn-secondary direction-link mt-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      colTextWImage?.button && colTextWImage?.button?.link?.url
                    }
                  >
                    {colTextWImage?.button && colTextWImage?.button.text}
                  </a>
                )}
              {colTextWImage?.button?.link &&
                colTextWImage?.button?.link?.target === "" && (
                  <button
                    className="btn btn-secondary direction-link mt-4"
                    onClick={() => navigate(`/${colTextWImage?.button.text}`)}
                  >
                    {colTextWImage?.button && colTextWImage?.button.text}
                  </button>
                )}
            </div>
          </div>
        </Container>
      )}
    </Section>
  )
}
